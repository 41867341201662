<template>
	<div class="pagination">
		<router-link :to="{ query: {...$route.query, ...prevParams }}" v-show="prevParams.startAt" title="Previous Page" class="mdc-icon-button">
			<span class="mdc-icon-button__ripple"></span>
			<i class="material-icons">navigate_before</i>
		</router-link>
		<div class="pagination__page">
			{{ count }} {{ 'Result' | pluralize(count) }}
		</div>
		<router-link :to="{ query: {...$route.query, ...nextParams }}" v-show="nextParams.startAt" title="Next Page" class="mdc-icon-button">
			<span class="mdc-icon-button__ripple"></span>
			<i class="material-icons">navigate_next</i>
		</router-link>
	</div>
</template>

<script>
export default {
	name: 'Pagination',
	props: {
		count: Number,
		limit: Number,
		first: Number | String,
		last: Number | String,
	},
	data: () => ({
		next: 1,
		prev: 1,
	}),
	computed: {
		route() {
			return this.$route
		},
		p() {
			return Number(this.route.query.p || 1)
		},
		nextParams() {
			return this.$store.getters['misc/nextParams'] || {}
		},
		prevParams() {
			return this.$store.getters['misc/prevParams'] || {}
		},
	},
	watch: {
		count: {
			immediate: true,
			handler: function (val) {
				this.prev = Math.max(1, (Number(this.$route.query.p || 1) - 1) )
				this.next = (Number(this.$route.query.p || 1) + 1)
			},
		},
		$route: {
			immediate: true,
			handler: function (val) {
				this.prev = Math.max(1, (Number(this.$route.query.p || 1) - 1) )
				this.next = (Number(this.$route.query.p || 1) + 1)
			},
		},
	},
}
</script>

<style scoped lang="scss">
@use "@material/icon-button/mdc-icon-button";

.pagination {
	@include modules.gutter('padding');
	align-items: center;
	color: modules.color_('text');
	display: flex;
	justify-content: center;
	line-height: 1;

	ion-icon {
		color: modules.color_('text', 'light');
		cursor: pointer;
		@include modules.fontSize(24px);
		transition: color 150ms linear;

		&:hover {
			color: modules.color_('secondary');
		}
	}

	&__page {
		@include modules.fontSize(15px);
		font-weight: 500;
	}
}
</style>
