<template>
	<div class="list list--forms list-forms">
		<table-list
			:items="form"
			:loading="isLoading"
			:noHover="true"
		>
			<template slot="header">
				<span
					:class="`forms-header-item forms-header-item--${key}`"
					:key="key"
					v-for="(col, key) in columns"
				>
					<component :is="orderBy[key] ? 'a' : 'span'" @click="sort(key)"> {{ col }}</component>
					<ion-icon :name="query.order == 'asc' ? `caret-up` : `caret-down`" v-if="orderBy[key] && orderBy[key] == query.orderBy"></ion-icon>
				</span>
			</template>

			<template slot="item" slot-scope="{ item }">
				<div
					:class="`forms-item forms-item--${key} forms-item--${item['status']}`"
					:key="`item_${key}`"
					v-for="(col, key) in columns"
					v-html="transform(key, get(item, key))"
				>
				</div>
			</template>

		</table-list>
	</div>
</template>

<script>
import { format, parseISO, formatDistanceToNow, formatDistanceToNowStrict } from 'date-fns'
import { get } from 'lodash'

import TableList from '@/components/TableList'
import { FormStatus } from '@/lib/enums'

export default {
	name: 'ListForms',
	components: {
		TableList,
	},
	props: {
		form: {
			type: Array,
			default: () =>([]),
		},
	},
	data: () => ({
		columns: {
			label: 'Label',
			name: 'Name',
			type: 'Type',
		},
		order: 'asc',
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		query() {
			return this.$route.query
		},
		orderBy() {
			let orderBy = {
				// nameEmail: 'firstName',
				// dateCreated: 'dateCreated',
				// organization: 'organization.name',
				// 'settings.salesforceId': 'salesforceId',
				// dateLogin: 'dateLogin',
				// status: 'status',
			}

			return orderBy
		},
		locationId() {
			return this.$route.params.locationId
		},
	},
	methods: {
		transform(key, value) {
			switch(key) {
				case 'dateCreated':
				case 'dateModified':
					try {
						value = `${format(parseISO(value), 'PPp')}`
					} catch (error) {
						try {
							value = `${format(value, 'PPp')}`
						} catch (error) {
							console.warn(error)
						}
					}
					return value
				case 'status':
					return FormStatus[value]
			}
			return value
		},
		sort(key) {

		},
		get,
	}
}
</script>

<style scoped lang="scss">
.list-forms {
	::v-deep .table-list {
		&__item,
		&__header {
			border-bottom: 1px solid modules.color_('border', 'light');
			display: grid;
			grid-template-columns:  repeat(3, minmax(100px, 1fr));

			@include modules.media-query('phone') {
				grid-template-columns: minmax(50%, 1fr) minmax(50%, 1fr);
			}
		}

		.list-forms__app-list {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}
	}

	.forms-header-item {
		@include modules.fontSize(14px);
		align-items: center;
		display: flex;
		font-weight: 600;
		padding: 1em 1.5em;

		@include modules.media-query('phone') {
			padding: 0.5em 1em;
		}
	}

	.forms-item {
		color: modules.color_('text', 'dark');
		font-weight: 500;
		overflow-wrap: break-word;
		padding: 1.5em;
		text-decoration: none;

		@include modules.media-query('phone') {
			@include modules.fontSize(15px);
			padding: 0.5em 1em;
		}

		&--pending {
			&.forms-item--status {
				color: modules.color_('alert', 'warn');
			}
		}

		&--removed {
			opacity: 0.7;

			&.forms-item--status {
				color: modules.color_('alert', 'error')
			}
		}
	}
}
</style>
