<template>
	<div class="modal modal--location modal-location">
		<div class="modal-location__form">
			<form-location :location="location" />
			<tab-bar
				:items="tabItems"
				class="modal-location__tabs">
				<template slot-scope="{ item, idx }" slot="item">
					<router-link
						class="tab-bar__item a"
						active-class="tab-bar__item--active"
						:class="{'tab-bar__item--active':item.link.hash == activeTab}"
						:id="`nav_tab_${idx}`"
						:to="item.link"
						>
						{{ item.label }}
					</router-link>
				</template>
			</tab-bar>

			<div class="form-row" v-if="activeTab == 'agreements'">
				<div class="form__actions">
					<router-link :to="{name: 'agreement', params: { locationId: $route.params.locationId} }" class="mdc-button mdc-button--primary form__action">
						<div class="mdc-button__ripple"></div>
						<i class="material-icons mdc-button__icon" aria-hidden="true">add</i>
						<span class="mdc-button__label">Add Agreement</span>
					</router-link>
				</div>
				<list-agreements :agreements="agreements" />
			</div>
			<div class="form-row" v-if="activeTab == 'form'">
				<div class="form__actions">
					<router-link :to="{name: 'form', params: { locationId: $route.params.locationId} }" class="mdc-button mdc-button--primary form__action">
						<div class="mdc-button__ripple"></div>
						<i class="material-icons mdc-button__icon" aria-hidden="true">edit</i>
						<span class="mdc-button__label">Edit Form</span>
					</router-link>
				</div>
				<list-forms :form="form" />
			</div>
			<div class="form-row" v-if="activeTab == 'settings'">
				<div class="form__actions">
					<router-link :to="{name: 'settings', params: { locationId: $route.params.locationId} }" class="mdc-button mdc-button--primary form__action">
						<div class="mdc-button__ripple"></div>
						<i class="material-icons mdc-button__icon" aria-hidden="true">edit</i>
						<span class="mdc-button__label">Edit Settings</span>
					</router-link>
				</div>
				<list-settings :settings="settings" />
			</div>
		</div>
		<div class="modal-location__stats">
			<dl>
				<dt>Date Created</dt>
				<dd>{{ location.dateCreated | date('PPp') }}</dd>
				<dt>Created By</dt>
				<dd>{{ location.createdByName }}</dd>
				<dt>Date Modified</dt>
				<dd>{{ location.dateModified | date('PPp') }}</dd>
				<dt>Modified By</dt>
				<dd>{{ location.modifiedByName }}</dd>
			</dl>

			<checkin-code :data="codeString" v-if="codeString" />
		</div>
	</div>
</template>

<script>
import CheckinCode from '@/components/CheckinCode'
import FormLocation from '@/components/FormLocation'
import ListAgreements from '@/components/ListAgreements'
import ListForms from '@/components/ListForms'
import ListSettings from '@/components/ListSettings'
import Modal from '@/components/Modal'
import TabBar from '@/components/TabBar'

export default {
	name: 'ModalLocation',
	components: {
		CheckinCode,
		FormLocation,
		ListAgreements,
		ListForms,
		ListSettings,
		Modal,
		TabBar,
	},
	mounted() {
		const { locationId } = this.$route.params
		this.$store.dispatch('client/list')
		if (locationId) {
			this.$store.dispatch('location/get', locationId)
			this.$store.dispatch('agreement/list', { locationId, })
			this.$store.dispatch('location/getForm', locationId, )
			this.$store.dispatch('location/getSettings', locationId, )
		}
	},
	destroyed() {
		this.$store.dispatch('location/unset')
	},
	data: () => ({
		tabItems: [
			{
				label: `Agreements`,
				link: {
					hash: 'agreements'
				}
			},
			{
				label: `Form`,
				link: {
					hash: 'form',
				},
			},
			{
				label: `Settings`,
				link: {
					hash: 'settings',
				},
			},
		],
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		location() {
			return this.$store.getters['location/location']
		},
		codeString() {
			if (!this.location || !this.location.locationId) return
			return `MEBKM:TITLE:${this.location.name}|${this.location.address};URL:https://getqp.app/o/${this.location.locationId};;`
		},
		modalVisible() {
			return this.$route.name == 'agreement'
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		activeTab() {
			let activeTab = this.$route.hash || `#agreements`
			return activeTab.replace('#','')
		},
		agreements() {
			const agreements = this.$store.getters['agreement/agreements']
			return agreements
		},
		form() {
			const form = this.$store.getters['location/form']
			return form
		},
		settings() {
			const settings = []
			const _settings = this.$store.getters['location/settings']
			for (const key in _settings) {
				settings.push({
					key,
					value: _settings[key],
				})
			}

			return settings
		},
	},
}
</script>

<style scoped lang="scss">
.modal-location {
	display: grid;
	grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	min-height: 50vh;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	&__tabs {
		@include modules.gutter('margin-left');
		@include modules.gutter('margin-right');
		width: auto;
	}

	.form-row {
		@include modules.gutter('margin-left');
		@include modules.gutter('margin-right');
	}
}
</style>
