<template>
	<form class="form form--form form" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row">
				<text-area class="form__field" label="" v-model="fields.form" :required="true" :rows="10" :code="true" @input="formChange" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button mdc-button--primary mdc-button--unelevated form__action" type="submit" :disabled="isLoading">
					Update Form
				</mdc-button>

				<mdc-button class="mdc-button--warn form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.formId && fields.status != 'removed'">
					Remove Form
				</mdc-button>
			</div>
		</div>

	</form>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextArea from '@/components/TextArea'
import TextField from '@/components/TextField'
import { FormStatus } from '@/lib/enums'
import MdcButton from '@/components/MdcButton'

export default {
	title: 'FormForm',
		components: {
		Checkbox,
		LoadingSpinner,
		MdcButton,
		SelectField,
		TextArea,
		TextField,
	},
	props: {
		form: {
			type: String,
			default: '',
		},
	},
	data: () => ({
		defaults: {
			status: 'active',
		},
		fields: {},
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
		formStatus() {
			let status = []
			for (let key in FormStatus) {
				status.push({
					value: key,
					label: FormStatus[key],
				})
			}

			return status
		},
	},
	mounted() {
		this.$store.dispatch('client/list')
	},
	methods: {
		formChange(val) {
			try {
				this.fields.form = JSON.stringify(JSON.parse(val), null, 4)
			} catch (error) {

			}
		},
		async submitForm(fields) {
			const { locationId, form } = fields
			try {
				JSON.parse(form)
			} catch (error) {
				console.log('json', error)
				return this.$notice(`JSON ERROR: ${error}`)
			}

			try {
				await this.$store.dispatch('location/updateForm', { locationId, form, })
				this.$notice(`Form updated.`)
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
			}
			// if (fields.formId) {
			// 	try {
			// 		const { formId } = fields
			// 		await this.$store.dispatch('form/update', { formId, fields, } )
			// 		this.$notice(`Form updated.`)
			// 	} catch (error) {
			// 		this.$notice(`ERROR: ${error.message || error}`)
			// 		console.error(error)
			// 	}
			// } else {
			// 	try {
			// 		const res = await this.$store.dispatch('form/create', fields)
			// 		this.$notice(`Form created.`)
			// 		this.$router.push({ title: 'form', params: { formId: res.formId, }})
			// 	} catch (error) {
			// 		this.$notice(`ERROR: ${error.message || error}`)
			// 	}
			// }
		},
	},
	watch: {
		form: {
			immediate: true,
			handler: function(props) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...props,
					...{
						locationId: this.$route.params.locationId,
						form: this.form,
					}
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
