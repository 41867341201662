<template>
	<div class="partial partial--locations locations">
		<div class="partial__heading">
			<h1 class="partial__title">Locations</h1>
			<div class="partial__actions">
				<router-link :to="{name: 'location' }" class="mdc-button mdc-button--primary mdc-button--outline">
					<div class="mdc-button__ripple"></div>
					<i class="material-icons mdc-button__icon" aria-hidden="true">add</i>
					<span class="mdc-button__label">Add Location</span>
				</router-link>
			</div>
		</div>
		<div class="partial__body">
			<card>
				<list-locations :locations="locations" :clients="clients" />
				<pagination :count="locations.length" :limit="limit" />
			</card>
		</div>
		<modal @close="hideModal" :open="modalVisible" :title="modalTitle">
			<router-view class="main__modal" slot="content" name="pageModal" />
		</modal>
	</div>
</template>

<script>
import Card from '@/components/Card'
import ListLocations from '@/components/ListLocations'
import Modal from '@/components/Modal'
import Pagination from '@/components/Pagination'

export default {
	name: 'PartialLocations',
	components: {
		Card,
		ListLocations,
		Modal,
		Pagination,
	},
	data: () => ({
		modalVisible: false,
		orderBy: 'clientId',
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		modalTitle() {
			return this.$route.meta.modalTitle
		},
		locations() {
			return this.$store.getters['location/locations']
		},
		clients() {
			return this.$store.getters['client/clients']
		},
		limit() {
			return this.$route.query.limit || 20
		},
	},
	methods: {
		hideModal(clicked) {
			console.log('locations.hideModal', clicked)
			this.modalVisible = false
			if (clicked) {
				this.$router.push({name: 'locations', })
				// this.$router.back()
			}
		},
	},
	metaInfo() {
		return {
			title: this.modalTitle || `Locations`,
		}
	},
	watch: {
		$route: {
			immediate: true,
			handler: function(to, from) {
				console.log('locations.route', { to, from, })
				if ((to && to.name == 'locations') && (from && from.name == 'location')) return
				const { orderBy, startAt, } = to.query
				this.$store.dispatch('location/list', { orderBy, limit: this.limit, startAt, })
				if (to.name == 'location')
					this.modalVisible = true
				// if (to.name == 'location' || to.name == 'agreement' || to.name == 'form' || to.name == 'settings') {
				// 	this.modalVisible = true
				// } else {
				// 	this.modalVisible = false
				// }
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
