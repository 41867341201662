<template>
	<div class="modal modal--setting modal-setting">
		<div class="modal-setting__setting">
			<form-settings :settings="settings" />
		</div>
	</div>
</template>

<script>
import FormSettings from '@/components/FormSettings'

export default {
	name: 'ModalForm',
	components: {
		FormSettings,
	},
	mounted() {
		const {  locationId } = this.$route.params
		if (locationId) {
			this.$store.dispatch('location/getSettings', locationId )
		}
	},
	destroyed() {
		this.$store.dispatch('setting/unset')
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		settings() {
			return this.$store.getters['location/settings']
		},
		location() {
			return this.$store.getters['location/location']
		},
	},
	methods: {
	},
}
</script>

<style scoped lang="scss">
.modal-setting {
	// display: grid;
	// grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	min-height: 50vh;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
