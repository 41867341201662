<template>
	<div class="checkin-code">
		<loading-spinner v-if="!qrcode" />
		<div class="checkin-code__box" v-html="qrcode"></div>
	</div>
</template>

<script>
import qrcode from 'qrcode-generator'
import LoadingSpinner from '@/components/LoadingSpinner'

export default {
	name: 'CheckinCode',
	components: {
		LoadingSpinner,
	},
	props: {
		data: String,
	},
	computed: {
		qrcode() {
			if (!this.data) return

			const typeNumber = 0
			const errorCorrectionLevel = 'L'
			let tag = ''

			try {
				const qr = qrcode(typeNumber, errorCorrectionLevel)
				qr.addData(this.data)
				qr.make()
				tag = qr.createImgTag(10, 0)
			} catch (error) {
				console.error(error)
				tag = 'CODE CAN NOT BE GENERATED'
			}


			return tag
		}
	}
}
</script>

<style scoped lang="scss">
.checkin-code {
	&__box {
		img {
			max-width: 100%;
		}
	}
}
</style>
