<template>
	<form class="form form--location location" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<select-field class="form__field" label="Client" v-model="fields.clientId" :options="listClients" placeholder="Select client..." :required="true" v-if="listClients.length > 1" />
				<text-field class="form__field" label="Name" v-model="fields.name" :required="true" />
				<text-field class="form__field" label="Address" v-model="fields.address" :required="true" :maxlength="25" />
				<text-field class="form__field" label="City" v-model="fields.city" :required="true" />
				<select-field class="form__field" label="State" v-model="fields.state" :options="states" />
				<text-field class="form__field" label="# of Terminals" type="tel" inputmode="numeric" v-model="fields.terminalCount" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="locationStatus" placeholder="Select Status..." :required="true" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button mdc-button--primary mdc-button--unelevated form__action" type="submit" :disabled="isLoading">
					{{ fields.locationId ? 'Update' : 'Create' }} Location
				</mdc-button>

				<mdc-button class="mdc-button--error form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.locationId && fields.status != 'removed'">
					Remove Location
				</mdc-button>
			</div>
		</div>

	</form>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import MdcButton from '@/components/MdcButton'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'

import { LocationStatus } from '@/lib/enums'
import stateList from '@/assets/stateList.json'

export default {
	name: 'FormLocation',
		components: {
		Checkbox,
		LoadingSpinner,
		MdcButton,
		SelectField,
		TextField,
	},
	props: {
		location: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			status: 'active',
		},
		fields: {},
		states: stateList,
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
		locationStatus() {
			return LocationStatus.toDropdown()
		},
		listClients() {
			const clients = JSON.parse(JSON.stringify(this.$store.getters['client/clients']))
			let items = [{value: null, label: ``}]
			for (const client of clients) {
				items.push({
					value: client.clientId,
					label: client.name,
				})
			}

			return items
		},

		// clients() {
		// 	return this.$store.getters['client/clients']
		// }
	},
	mounted() {
		this.$store.dispatch('client/list')
	},
	methods: {
		async submitForm(fields) {
			if (fields.locationId) {
				try {
					const { locationId } = fields
					await this.$store.dispatch('location/update', { locationId, data: fields, } )
					this.$notice(`Location updated.`)
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
					console.error(error)
				}
			} else {
				try {
					const res = await this.$store.dispatch('location/create', fields)
					this.$notice(`Location created.`)
					this.$router.push({ name: 'location', params: { locationId: res.locationId, }})
				} catch (error) {
					this.$notice(`ERROR: ${error.message || error}`)
				}
			}
		},
	},
	watch: {
		location: {
			immediate: true,
			handler: function(props) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...props,
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
