<template>
	<form class="form form--settings settings" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<select-field v-model="fields.curbside" :options="curbsideOptions" label="Curbside" />
			</div>

			<div class="form__actions form__actions--horizontal">
				<mdc-button class="mdc-button mdc-button--primary mdc-button--unelevated form__action" type="submit" :disabled="isLoading">
					Update Settings
				</mdc-button>

				<mdc-button class="mdc-button--warn form__action" type="button" :disabled="isLoading" @click="remove(fields)" v-if="fields.settingsId && fields.status != 'removed'">
					Remove Settings
				</mdc-button>
			</div>
		</div>

	</form>
</template>

<script>
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import MdcButton from '@/components/MdcButton'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'

export default {
	name: 'FormSettings',
		components: {
		Checkbox,
		LoadingSpinner,
		MdcButton,
		SelectField,
		TextField,
	},
	props: {
		settings: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			status: 'active',
		},
		fields: {},
		curbsideOptions: [
			{
				label: 'None',
				value: false,
			},
			{
				label: 'Description',
				value: 'description',
			},
			{
				label: 'Numbered',
				value: 'number',
			},
		],
	}),
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		error() {
			return this.$store.getters['misc/error']
		},
	},
	mounted() {
	},
	methods: {
		async submitForm(fields) {
			try {
				const { locationId } = this.$route.params
				await this.$store.dispatch('location/updateSettings', { locationId, settings: fields, } )
				this.$notice(`Location updated.`)
			} catch (error) {
				this.$notice(`ERROR: ${error.message || error}`)
				console.error(error)
			}
		},
	},
	watch: {
		settings: {
			immediate: true,
			handler: function(props) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...props,
				}
			},
		},
	},
}
</script>

<style scoped lang="scss">

</style>
