<template>
	<label class="checkbox">
		<input
			type="checkbox"
			class="checkbox__input"
			:id="id"
			:checked="value"
			:disabled="disabled"
			v-bind="attrs"
			v-on="listeners"
		>
		<span class="checkbox__box">
			<i class="material-icons">check</i>
		</span>
		<span class="checkbox__label">{{label}}</span>
	</label>
</template>

<script>
export default {
	name: 'checkbox',
	inheritAttrs: false,
	props: {
		value: Boolean,
		id: String,
		label: {
			type: String,
			default: '',
		},
		hint: {
			type: String,
			default: '',
		},
		type: {
			type: String,
			default: 'checkbox',
		},
		checked: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		listeners() {
			return {
				...this.$listeners,
				input: event => this.input(event),
			}
		},
		attrs() {
			const { ...attrs } = this.$attrs

			return attrs
		},
	},
	methods: {
		input(evt) {
			this.$emit('input', evt.target.checked)
		},
	},
}
</script>

<style scoped lang="scss">
$size: 18px;
.checkbox {
	// @include font-size('caption');
	align-items: center;
	cursor: pointer;
	display: flex;
	position: relative;


	&__label {
		padding-left: 1em;
	}

	.material-icons {
		font-size: 18px;
	}

	&__input {
		cursor: pointer;
		height: 0;
		opacity: 0;
		position: absolute;
		width: 0;


		&:checked {
			~ .checkbox__box {
				background-color: modules.color_('primary');
				border-color: modules.color_('primary');
				// background-color: var(--color-primary);
				// border-color: var(--color-primary);

				&:before,
				.material-icons {
					opacity: 1;
				}
			}
		}

		&:disabled {
			~ .checkbox__box {
				background-color: modules.color_('border', 'dark');
				border-color: modules.color_('border','dark');
				cursor: not-allowed;

				&:before,
				.material-icons {
					opacity: 1;
				}
			}
		}
	}

	&__box {
		align-items: center;
		justify-content: center;
		border: 2px solid modules.color_('border', 'dark');
		// border: 2px solid var(--color-border-dark);
		border-radius: 2px;
		display: flex;
		height: $size;
		width: $size;
		transition: border 150ms linear;

		.material-icons {
			color: white;
			opacity: 0;
			transition: 150ms linear;
		}

		// &:before {
			// content: '\f121';
			// color: white;
			// display: block;
			// font-family: "ionicons";
			// font-size: $size - 2;
			// // font-weight: 200;
			// height: $size - 2;
			// line-height: $size - 2;
			// opacity: 0;
			// text-align: center;
			// width: $size - 2;
			// transition: 150ms linear;
		// }
	}
}
</style>
