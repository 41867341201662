<template>
	<div class="modal modal--form modal-form">
		<div class="modal-form__form">
			<form-form :form="formString" />
		</div>
	</div>
</template>

<script>
import FormForm from '@/components/FormForm'

export default {
	name: 'ModalForm',
	components: {
		FormForm,
	},
	mounted() {
		const {  locationId } = this.$route.params
		if (locationId) {
			this.$store.dispatch('location/getForm', locationId )
		}
	},
	destroyed() {
	},
	computed: {
		isLoading() {
			return this.$store.getters['misc/loading']
		},
		form() {
			return this.$store.getters['location/form']
		},
		formString() {
			return JSON.stringify(this.form, null, 4)
		},
		location() {
			return this.$store.getters['location/location']
		},
	},
	methods: {
	},
}
</script>

<style scoped lang="scss">
.modal-form {
	// display: grid;
	// grid-template-columns: 2fr minmax(10rem, 1fr);
	min-width: 70vw;
	min-height: 50vh;
	width: 100%;

	@include modules.media-query-max-width('desktop-xs') {
		grid-template-columns: 1fr;
	}

	dl {
		@include modules.fontSize(14px);
		@include modules.gutter('grid-row-gap', 0.5);
		display: grid;
		grid-column-gap: 0;
		grid-template-columns: 1fr auto;
	}

	dd {
		margin: 0;
		text-align: right;
		padding: 0.5rem;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}

	dt {
		font-weight: 500;
		padding: 0.5rem;
		text-transform: uppercase;

		&:nth-of-type(odd) {
			background-color: modules.color_('background', 'light');
		}
	}
}
</style>
